import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {checkData} from "../../helper";
import {mediaBaseURL} from "../../utils/constant";
import HtmlParser from "react-html-parser";

function Challenges({data}) {
    return (
        <>
            <div className="challenges_section" style={{
                background: `linear-gradient(0deg, #ffffff 20%, ${checkData(data, 'bg_light_color')} 20%, ${checkData(data, 'bg_light_color')} 80%, #ffffff 80%)`
            }}>
                <Container className="h-100">
                    <Row className="h-100">
                        <Col xs={12} md={10} lg={8} xl={8} xxl={8} className="m-auto text-center img_position">
                            <img
                                src={`${mediaBaseURL}${checkData(data, 'solutions_image')}`}
                                className="img-fluid"
                                alt={checkData(data, 'solutions_title')}/>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="p-100">
                <Container className="h-100">
                    <Row className="h-100">
                        <Col xs={12} md={10} lg={10} xl={10} xxl={10} className="m-auto text-center">
                            <h2>{checkData(data, 'solutions_title')}</h2>
                            <div>{HtmlParser(checkData(data, 'solutions_description'))}</div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default Challenges;